/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { Button } from './Button'
import phone from './phone.png'
import insta from './insta.png'
import fb from './fb.png'
import skype from './skype.png'
import email from './email.png'
import bg_kapcsolat from './kapcsolat.jpg'
import { useRef } from 'react'
import fayoga from './cta FaYogAlive.png'

const Input = styled.input`
	background-color: var(--panel-bgcolor);
	border: none;
	padding: 0.6rem;
	border-radius: 0.5rem;
	color: currentColor;
	font-size: 1.1rem;
	width: 28rem;
	max-width: 90vw;
	margin: 0.3rem 0;
	font-family: inherit;
`

const Contact = styled.div`
	margin: 1vmin;
	margin-left: 4vw;
`
const ContactLink = styled.span`
	display: flex;
	align-items: center;
	word-break: break-word;
`

const ContactImg = styled.a`
	margin-right: 1rem;
	flex-shrink: 0;
`

export function Kapcsolat({ text, lang }) {
	const form = useRef(null)
	const onClick = () => {
		// let formEl = document.getElementById('form')
		let formEl = form.current
		let formData = new FormData(formEl)

		fetch(`/api/subscribe.php?lang=${lang}`, {
			method: 'POST',
			body: formData,
		}).then(async (response) => {
			const data = await response.json();
			if (response.ok) {
				return data;
			} else {
				return Promise.reject(data);
			}
		}).then(data => {
			switch (data.message) {
				case 'OK': {
					alert(text['alert_success'])
					break
				}
				default: {
					alert(text['alert_error'])
					break
				}
			}
		})
	}

	return (
		<main id={text['pages']['contact_page']} css={css`
			display: grid;
			@media (min-width: 1200px) {
				grid-template:
					'links form'
					/ 1fr 1fr;
			}
			@media (max-width: 1200px) {
				grid-template:
					'links'
					'form'
					'yoga';
			}
			align-items: center;
			align-content: center;
			min-height: 100vh;
			width: 100vw;
			position: relative;
			z-index:-1;
		`}>
			<div style={{
				position: 'absolute',
				zIndex: -2,
				backgroundPosition: 'center center',
				backgroundImage: `url(${bg_kapcsolat})`,
				backgroundSize: 'cover',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
			}}></div>
			<div>
				<address css={css`
					font-style: normal;
					display: flex;
					flex-direction: column;
					margin-top: 3rem;
				`}>
					<Contact>
						<ContactLink><ContactImg target='_blank' href="https://www.facebook.com/guitarmat3"><img src={fb} alt='facebook' css={css`	height: 14vmin;`} /></ContactImg><a target='_balnk' href="https://www.facebook.com/guitarmat3">facebook.com/guitarmat3</a></ContactLink>
					</Contact>
					<Contact>
						<ContactLink><ContactImg target='_blank' href="https://www.instagram.com/karolyimate"><img src={insta} alt='instagram' css={css`	height: 14vmin;`} /></ContactImg><a target='_balnk' href="https://www.instagram.com/karolyimate">instagram.com/karolyimate</a></ContactLink>
					</Contact>
					{/* <Contact>
						<ContactLink><ContactImg target='_blank'  href="https://www.join.skype.com/invite/jv3xlaqhCMlV"><img  src={skype} alt='skype' css={css`	height: 14vmin;`}/></ContactImg><a  target='_balnk'  href="https://www.join.skype.com/invite/jv3xlaqhCMlV">join.skype.com/invite/jv3xlaqhCMlV</a></ContactLink>
					</Contact> */}
					<Contact>
						<ContactLink><ContactImg target='_blank' href="mailto:guitarmat3@gmail.com"><img src={email} alt='email' css={css`	height: 14vmin;`} /></ContactImg><a target='_balnk' href="mailto:guitarmat3@gmail.com">guitarmat3@gmail.com</a></ContactLink>
					</Contact>
					<Contact>
						<ContactLink><ContactImg target='_blank' href='tel:+36305197735'><img src={phone} alt='telefon' css={css`	height: 14vmin;`} /></ContactImg><a target='_balnk' href='tel:+36305197735'>+36305197735</a></ContactLink>
					</Contact>
					{lang === "hu" ? (
						<div style={{
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							borderRadius: '0.75rem',
							width: "max-content",
							margin: '1vmin',
							marginLeft: '4vw',
							fontSize: '1rem',
							display: 'flex',
							flexDirection: "column",
							alignItems: "center",
						padding: '0.5rem 0',
						}}
						css={css`
						@media (max-width: 1200px) {
							display: none !important;
						}
			`}>
							<p>Ha érdeklődsz a jóga iránt is,<br /> akkor van egy biztos tippem:</p>
							<a href="https://www.facebook.com/fayogalive/"><img style={{
								height: '3rem',
								margin: '1rem',
							}} src={fayoga} alt="" /></a>
						</div>
					) : null}
				</address>
			</div>
			<div css={css`
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-self: center;
					margin: 4rem 0;
				`}>
				<form /* action='/api/subscribe.php' method='POST'*/ id='form' ref={form} css={css`
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-self: center;
				`} >
					<p css={css`font-size:2rem;font-weight:700;`}>{text['contact'][0]}</p>
					<p css={css`font-size:1.3rem;`}>{text['contact'][1]}</p>
					<Input placeholder={text['contact'][2]} type="email" name="email" id="email" />
					<p>{text['contact'][3]}</p>
					<p css={css`font-size:1.3rem;`}>{text['contact'][4]}</p>
					<Input placeholder={text['contact'][5]} type="tel" name="tel" id="tel" />
					{/* <p>és</p>
					<p css={css`font-size:1.3rem;`}>a kuponkódodat</p>
					<Input placeholder='írd be ide a kuponkódodat' type="text" name="code" id="code"/> */}
					<p>{text['contact'][6]}</p>
				</form>
				<Button onClick={onClick} /*type='submit'*/>{text['contact'][7]}</Button>
			</div>
			{lang === "hu" ? (
						<div style={{
							gridArea: 'yoga',
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							borderRadius: '0.75rem',
							width: "max-content",
							margin: '1vmin',
							marginBottom: "4rem",
							fontSize: '1rem',
							display: 'flex',
							flexDirection: "column",
							alignItems: "center",
						padding: '0.5rem 0',
						alignSelf: "center",
						justifySelf: "center",
						}}
						css={css`
						@media (min-width: 1201px) {
							display: none !important;
						}
			`}>
							<p>Ha érdeklődsz a jóga iránt is,<br /> akkor van egy biztos tippem:</p>
							<a href="https://www.facebook.com/fayogalive/"><img style={{
								height: '3rem',
								margin: '1rem',
							}} src={fayoga} alt="" /></a>
						</div>
					) : null}
		</main>
	)
}