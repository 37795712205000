/** @jsx jsx */
import styled from '@emotion/styled'

export const Button = styled.button`
	background-color: var(--button);
	color: currentColor;
	font-size: 1.2rem;
	padding: 14px 33px;
	margin: 1rem;
	border: none;
	border-radius: 10px;
	font-family: inherit;
	font-weight: 700;
	position: relative;
	z-index:10;
	cursor: pointer;
`
