export const texts = {
	'hu': {
		home_page: "kezdőlap",
		about_page: "rólam",
		contact_page: "kapcsolat",
		hero_text: 'GITÁR. ZENE. EGÉSZSÉG.',
		contact_us: 'lépjünk kapcsolatba',
		change_lang: 'switch to English',
		why_q: 'Miért zenélek?',
		why_a: 'egy szó: egészség',
		till_when_q: 'Mióta gitározok?',
		till_when_a: 'nagyjából 12 éve foglalkozom gitározással',
		how_q: 'Milyen formában tartom az órákat?',
		how_a: 'az órákat online (főként ZOOM-on) tartom, vagy szívesen házhoz is megyek',
		what_q: 'Milyen zenéket tanítok?',
		what_a: `a zenei stílusom nagyon sokszínű,
		így szinte minden stílust játszok és oktatok (pl.: fingestyle, punk, metal, stb.)`,
		who_q: 'Kiknek?',
		who_a: `bármilyen életkorú és nemű tanítványt szívesen vállalok.
		én zenélek, a zenét szeretem
		ha te is így vagy ezzel, számomra csak jó ember lehetsz.`,
		when_q: 'Milyen időbeosztásban?',
		when_a: `rugalmasan, ami neked megfelelő.
		a gitároktatás mellett olyan dolgokat végzek,
		amelyek lehetővé teszik számomra a szabados időbeosztást.`,
		szolfezs_q: 'Van szolfézs?',
		szolfezs_a: `nincs.
		nem szeretem a haszontalanul unalmas / sok zeneelméletet.
		majd zenélés közben, észrevétlen megtanítom, amire szükséged van.
		a zene az, amit szeretek!`,
		contact: [
			"Add meg az",
			"email címedet",
			"írd be ide az email címedet",
			"vagy",
			"a telefonszámodat",
			"írd be ide a telefonszámodat",
			"és",
			"lépjünk kapcsolatba",
		],
		pages: {
			home_page: "kezdolap",
			about_page: "rolam",
			contact_page: "kapcsolat",
		},
		alert_success: `Köszönöm szépen bizalmad!\nHamarosan kereslek :)`,
		alert_error: `Sajnálom, de hiba történt az adataid rögzítése során. :(\nTovábbra is kapcsolatba léphetsz velem egyéb elérhetőségeimen,\nés megköszönném, ha jeleznéd nekem a hibát.`,
		meta_description: `Károlyi Máté gitár magántanár vagyok. A gitár oktatásával célom, hogy a tanítványaim testi és lelki egészségét támogassam. Az órákat online (főként ZOOM-on) tartom, vagy szívesen házhoz is megyek.`
	},
	'en': {
		home_page: "home",
		about_page: "about me",
		contact_page: "contact",
		hero_text: 'GUITAR. MUSIC. HEALTH.',
		contact_us: 'get in touch',
		change_lang: 'váltás Magyarra',
		why_q: 'What is your intention to play?',
		why_a: `it's simple: health`,
		till_when_q: 'How long have you been playing?',
		till_when_a: `it's about 12 years by now`,
		how_q: 'Where?',
		how_a: `online, preferably through ZOOM (if you do not know it yet, don't worry it's easy to use)`,
		what_q: 'Which style?',
		what_a: `my style is diversfied to almost all genres (eg.: fingerstyle, punk, metal, etc.)`,
		who_q: 'To whom do you recommend it?',
		who_a: `everyone from all the ages and genders.
		I love music, and if you think it the same way, I consider you as a good person.`,
		when_q: 'What is your schedule?',
		when_a: `my schedule is flexible the only exception is that I'm not teaching on weekends.`,
		contact: [
			"If you think the same way...",
			"email adress",
			"type your email adress here",
			"or ",
			"phone number",
			"type your phone number here",
			"and",
			"get in touch",
		],
		pages: {
			home_page: "home",
			about_page: "about",
			contact_page: "contact",
		},
		alert_success: `My days are brighter now!\nI'll contact you as soon as I can.`,
		alert_error: `Sorry for the inconvenience, but an unexpected error occurred when the website was trying to collect your data.\nYou can still get in touch with me through my contact details listed on the contact page.\nSee ya!`,
		meta_description: `I'm Mate Karolyi Guitar tutor. My goal is to help my students' overall health by showing them the love and passion which only music can give to one.`
	}
}




