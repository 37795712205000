/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useRef, useEffect, useReducer } from 'react';
import { Kezdolap } from './kezdolap';
import { Kapcsolat } from './kapcsolat';
import { Rolam } from './rolam';
import logo from './logo.png'
import { useMorph } from 'react-morph';
import { texts } from './lang';


function A({ isActive, isHover, isSame, children, activeMorph, hoverMorph, onMouseEnter, onMouseLeave, ...rest }) {
  return (
    <div css={css`
      position: relative;
      padding: 0 0.5rem;
      z-index:20 !!important;
      `}
      onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <a {...rest}
        css={css`
            /* border-bottom: 2px solid transparent;
          &:hover {
            border-bottom: 2px solid gray;
              } */
        display: block;
        padding: 0.5rem 0.1rem 0.8rem;
          text-decoration: none;
          color: inherit;
          /* border-bottom: ${isActive ? '2px solid currentColor' : 'none'}; */
        `}
      >
        {children}
      </a>
      {!isSame && isHover && <span css={css`
        @media (max-width: 1200px) {
          display: none;
        }
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: gray;
      `} {...hoverMorph} />}
      {isSame && isActive && <span css={css`
        @media (max-width: 1200px) {
          display: none;
        }
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: white;
      `} {...hoverMorph} />}
      {isActive && <span css={css`
        @media (min-width: 1200px) {
          display: none;
        }
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: white;
      `} />}
    </div>
  )
}

const Page = styled.div`
position: relative;
  flex-basis: 100vh;
  flex-grow: 1;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  z-index: -1;
`

const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints)


function App() {

  const [lang, setLang] = useState(() => {
    return (new URL(document.location)).searchParams.get('lang') ?? 'hu'
  })


  useEffect(() => {
    const url = new URL(document.location)
    if (lang !== url.searchParams.get('lang')) {
      url.searchParams.set('lang', lang)
      window.location = url.toString()
    }
  }, [lang])

  const text = texts[lang]

  useEffect(() => {
    console.log(document.querySelector('meta[name="description"]').getAttribute('content'))
    console.log(text['meta_description'])
    document.querySelector('meta[name="description"]').setAttribute("content", text['meta_description']);
  }, [text])

  const pages = [text['pages']['home_page'], text['pages']['about_page'], text['pages']['contact_page'],]
  const [page, setPage] = useState(() => {
    const urlPage = pages.indexOf(window.location.hash)
    return urlPage !== -1 ? urlPage : 0

  })
  const turnPage = (arg) => {
    setPage(arg)
    !isTouchDevice && setHover(arg)
    update()
  }
  const [hover, setHover] = useState(page)
  const [lastScroll, setLastScroll] = useState(Date.now())
  const onWheel = (e) => {
    if (Date.now() - lastScroll > 1000) {
      let dir = (e.wheelDelta || -1 * e.deltaY) > 0 ? -1 : 1
      turnPage((p) => clamp(0, p + dir, 2))
      setLastScroll(Date.now())
    } else {
      e.preventDefault()
    }
  }
  const onMouseEnter = (num) => (e) => {
    setHover(num)
  }

  const onMouseLeave = (e) => {
    setHover(page)
  }


  const update = useForceUpdate()
  const pageRefs = [useRef(null), useRef(null), useRef(null)]
  useEffect(() => {
    if (!isTouchDevice) {
      if (Date.now() - lastScroll < 2000) {
        pageRefs[page].current.scrollIntoView({ behavior: 'smooth' })
        setTimeout(() => {
          update()
        }, 600);
      }
      // setTimeout(() => clearInterval(handler), 700)  
      window.onresize = () => pageRefs[page].current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [page, pageRefs, update, lastScroll])


  // useEffect(() => {
  //   if (isTouchDevice) {
  //     pageRefs[page].current.scrollIntoView()
  //     update()
  //   }
  // }, [page, pageRefs, update])
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!isTouchDevice) {
  //       pageRefs[page].current.scrollIntoView({behavior: 'smooth'})
  //       window.onresize = () => pageRefs[page].current.scrollIntoView({behavior: 'smooth'})
  //     }
  //   }, 500)  }, [page, pageRefs])
  useEffect(() => {
    if (!isTouchDevice) {
      window.location.hash = pages[page]
    }
  }, [page, pages])

  const activeMorph = useMorph()
  const hoverMorph = useMorph({ spring: { mass: 0.1 } })

  // kupon
  // const message = useCallback(setTimeout(() => alert('Kuponkódod: hd2i7cd3'), 5000), [])
  // useEffect(message, [])
  // useTimeoutEffect(() => alert('Kuponkódod: guitar1024\nKüldd el ezt nekem, és két gitárórát ingyen kipróbálhatsz! :)'), 2000)

  useEffect(() => { fetch(`/api/stats.php?lang=${lang}`, { method: 'GET' }) }, [lang])

  return (
    <div
      css={css`
        position: relative;
        z-index: -1;
        /* mouse, touch pad */
        @media (hover: hover) and (pointer: fine) and (min-width: 1200px) {
          display: grid;
          grid-template-columns: repeat(3, min-content);
          overflow: hidden scroll;
          min-height: 100vh;
          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }
        flex-direction: column;
        scroll-behavior: smooth;
        overflow: scroll hidden;
        /* scroll-snap-type: x mandatory; */
      `}
      onWheel={!isTouchDevice && onWheel}
    >
      <header css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 3rem;
        background-color: var(--panel-bgcolor);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index:0;

      `}>
        <h1 css={css`
          margin: 0;
          font-size: 1rem;
        `}><img src={logo} alt='guitarmat3' /></h1>
        {!isTouchDevice && <nav css={css`
          display: flex;
          @media (max-width: 600px) {
            display: none;
          }
        `}>
          <A href={`#${text['pages']['home_page']}`} activeMorph={activeMorph} hoverMorph={hoverMorph} onClick={() => turnPage(0)} onMouseEnter={onMouseEnter(0)} onMouseLeave={onMouseLeave} isHover={hover === 0} isActive={page === 0} isSame={page === hover}>{text['home_page']}</A>
          <A href={`#${text['pages']['about_page']}`} activeMorph={activeMorph} hoverMorph={hoverMorph} onClick={() => turnPage(1)} onMouseEnter={onMouseEnter(1)} onMouseLeave={onMouseLeave} isHover={hover === 1} isActive={page === 1} isSame={page === hover}>{text['about_page']}</A>
          <A href={`#${text['pages']['contact_page']}`} activeMorph={activeMorph} hoverMorph={hoverMorph} onClick={() => turnPage(2)} onMouseEnter={onMouseEnter(2)} onMouseLeave={onMouseLeave} isHover={hover === 2} isActive={page === 2} isSame={page === hover}>{text['contact_page']}</A>
        </nav>}
      </header>

      <Page ref={pageRefs[0]}>
        <Kezdolap text={text} lang={lang} setLang={setLang} onContact={() => turnPage(2)} isTouchDevice={isTouchDevice} />
      </Page>
      <Page ref={pageRefs[1]}>
        <Rolam text={text} lang={lang} />
      </Page>
      <Page ref={pageRefs[2]}>
        <Kapcsolat text={text} lang={lang} />
      </Page>
      {/* <Router css={css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-content: stretch;
        justify-content: center;
      `}>
        <Kezdolap path='/' default />
        <Rolam path='/rolam' />
        <Kapcsolat path='/kapcsolat' />
      </Router> */}
    </div>
  );
}

export default App;

function clamp(min, val, max) {
  return Math.max(min, Math.min(val, max))
}


function useForceUpdate() {
  return useReducer(() => ({}), {})[1] // <- paste here
}

