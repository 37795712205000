/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { texts } from './lang'
import bg_rolam from './rolam.jpg'

const Li = styled.li`
	border-left: 2px rgb(163, 8, 8) solid;
	background: linear-gradient(90deg, var(--button), rgba(7, 4, 4, 0.4));
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	width: 40rem;
	max-width: 90vw;
	padding: 1rem 0.5rem;
	margin: 1rem;
	@media (min-width: 1201px) {
		/* margin: 0; */
		max-width: 45vw;
		@media (max-height: 600px) {
			max-width: 30vw;
			margin: 0.5rem;
		}
	}

`

const Title = styled.h2`
	font-size: 1.5rem;
	margin: 0 0 0.5rem;
`

const P = styled.p`
	font-size: 0.95rem;
	`

const NewlineText = ({text}) => text.split('\n').map(str => <p style={{margin: 2}}>{str}</p>)
 

export function Rolam({ text, lang }) {

	return (
		<main id={text['pages']['about_page']} css={css`
			min-height: 100vh;
			width: 100%;
			flex-basis: 100%;
			display: flex;
			align-items: stretch;
			position: relative;
			z-index:-1;

			@media (max-width: 1200px) {
				justify-content: center;
			}
		`}>
			<div style={{
				position: 'absolute',
				zIndex: -1,
				backgroundPosition: 'center center',
				backgroundImage: `url(${bg_rolam})`,
				backgroundSize: 'cover',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
			}}></div>
			<ul css={css`
				margin: 4rem 0 1rem 2rem;
				padding: 0;
				/* display: grid;
				align-items: center;
				justify-items: center;
				column-gap: 2rem;
			@media (min-height: 800px) {
				grid-template:
				'_1 .'
				'_2 .'
				'_3 .'
				'_4 .'
				'_5 _7'
				'_6 _7';
			}
			@media (max-height: 800px) and (min-height: 700px) {
				grid-template:
				'_1 .'
				'_2 .'
				'_3 _7'
				'_4 _7'
				'_5 _6';
			}
			@media (max-height: 700px) and (min-height: 600px) {
				grid-template:
				'_1 _7'
				'_2 _7'
				'_3 _6'
				'_4 _5';
			} */
			@media (max-height: 600px) {
				/* display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				margin: 4rem 2rem 0;
				max-height: 80vh; */
				justify-content: center;
				align-items: center;
			}
			display: flex;
				flex-direction: column-reverse;
				flex-wrap: wrap;
				max-height: 90vh;

			@media (max-width: 1200px) {
				display: block;
				margin: 2rem 0;
				max-height: unset;
			}
			align-items: center;
			`}>
				<Li css={css`
					grid-area: _1;
				`}>
					<Title>{text['why_q']}</Title>
					<P><NewlineText text={text['why_a']} /></P>
				</Li>
				<Li css={css`
					grid-area: _2;
				`}>
					<Title>{text['till_when_q']}</Title>
					<P><NewlineText text={text['till_when_a']} /></P>
				</Li>
				<Li css={css`
					grid-area: _3;
				`}>
					<Title>{text['how_q']}</Title>
					<P><NewlineText text={text['how_a']} /></P>
				</Li>
				<Li css={css`
					grid-area: _4;
				`}>
					<Title>{text['what_q']}</Title>
					<P><NewlineText text={text['what_a']} /></P>
				</Li>
				<Li css={css`
					grid-area: _5;
				`}>
					<Title>{text['who_q']}</Title>
					<P><NewlineText text={text['who_a']} /></P>
				</Li>
				<Li css={css`
					grid-area: _6;
				`}>
					<Title>{text['when_q']}</Title>
					<P><NewlineText text={text['when_a']} /></P>
				</Li>
				{lang === 'hu' ? (
					<Li css={css`
					grid-area: _7;
				`}>
						<Title>{text['szolfezs_q']}</Title>
						<P><NewlineText text={text['szolfezs_a']} /></P>
					</Li>
				) : null}

			</ul>

		</main>
	)
}