/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button } from './Button'
import video from './video.webm'
import bg_kezdolap from './index.jpg'
import { useState } from 'react'
import sound from './music.png'
import { useRef } from 'react'
import { texts } from './lang'
import hu from './hungary.svg'
import en from './united-kingdom.svg'

export function Kezdolap({ onContact, isTouchDevice, lang, setLang, text }) {
	const [muted, setMuted] = useState(true)
	const videoRef = useRef(null)
	// useEffect(() => {
	// 	try {
	// 		video.current.play()
	// 	} catch (e) {
	// 		setMuted(true)
	// 	}
	// })
	function turnSoundOn() {
		setMuted(false)
	}

	const otherLang = lang === 'hu' ? 'en' : 'hu'


	return (
		<div>
			<div css={css`
				display: grid;
				place-items: center;
				width: 100vw;
				height: 100vh;
				position: absolute;
				overflow: hidden;
				z-index: -3;
			`}>
				<video css={css`
					min-height: 100vw;
					object-fit: cover;
					min-height: 100vh;
				`}
					playsInline preload autoPlay buffered loop poster={bg_kezdolap} ref={videoRef}>
					<source src={!isTouchDevice && video} type="video/webm" />
				</video>
			</div>
			<main id={text['pages']['home_page']} css={css`
				display: flex;
				position: absolute;
				z-index: -1;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				min-height: 100%;
				width: 100%;
				flex-grow: 1;
				background-color: rgba(0, 0, 0, 0.6);
				overflow: hidden;
				padding-top: 4rem;
			`}>
				<button style={{
					position: 'absolute',
					top: '4rem',
					left: '3rem',
					backgroundColor: "transparent",
					padding: 0,
					fontSize: "1.25rem",
					fontWeight: 700,
					color: "rgb(255, 255, 255, 0.5)",
					border: "none",
					cursor:"pointer",
					fontFamily: "inherit",
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
					onClick={() => setLang(otherLang)}>
						<span>{text['change_lang']}</span>
						<img style={{
							height: '2.5rem',
							opacity: 0.5,
							marginTop: '0.25rem',
						}} src={lang === 'hu' ? en : hu} alt='' />
						</button>
				<h1 css={css`
					font-size: 3rem;
					margin-bottom: 3rem;
					text-align: center;
				`}>{text['hero_text']}</h1>
				{<a href={`#${text['pages']['home_page']}`} rel='internal' onClick={onContact}
					css={css`
						background-color: var(--button);
						color: currentColor;
						font-size: 1.2rem;
						padding: 14px 33px;
						margin: 1rem;
						border: none;
						border-radius: 10px;
						font-family: inherit;
						font-weight: 700;
						position: relative;
						z-index:10;
						cursor: pointer;`}
				>{text['contact_us']}</a>}
				<div css={css`
						position: relative;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: flex-start;
						height: 6rem;
						`}>
					{/* {muted && !isTouchDevice &&
						<Button css={css`
							background-color: rgba(93, 93, 93, 0.5);
							border-color: rgba(0, 0, 0, 0.5);
							border-width: 3px;
							border-radius: 0;
							border-style: solid;
							color: black;
							position: relative;
							display: flex;
							padding-block: 0.7rem;
							margin-block: 0.2rem ;
						`} onClick={turnSoundOn}>
							<img src={sound} alt='hang bekapcsolása' css={css`
								height: 2rem;
								opacity: 0.8;
								top: 6px;
								left: 12px;
								position: absolute;
							`} />
							<span css={css`
								position: relative;
								left: 20px;
							`}>hang bekapcsolása</span>
						</Button>} */}
				</div>
			</main>
		</div>
	)
}